import { globalStore } from "@/lib/store";

export interface Theme {
  name: string;
  type: "light" | "dark";
  editor: {
    background: string;
    foreground: string;
    lineHighlight: string;
    selection: string;
    cursor: string;
    gutterBackground: string;
    gutterForeground: string;
  };
  syntax: {
    keyword: string;
    name: string;
    function: string;
    constant: string;
    string: string;
    number: string;
    comment: string;
    operator: string;
    class: string;
    type: string;
    variable: string;
  };
  ui: {
    sideBar: string;
    activityBar: string;
    titleBar: string;
    tabs: string;
    tabsActive: string;
    tabsActiveBorder: string;
    borders: string;
    buttons: string;
    buttonsHover: string;
    text: string;
    titleBarText: string;
    sideBarText: string;
    activityBarText: string;
    panels: string;
  };
}

export const defaultThemes: Theme[] = [
  {
    name: "Enhanced Material",
    type: "dark",
    editor: {
      background: "#292c3e",
      foreground: "#b4b4b4",
      lineHighlight: "#1110173c",
      selection: "#666666",
      cursor: "#b3b4b2",
      gutterBackground: "#292c3e",
      gutterForeground: "#858585",
    },
    syntax: {
      keyword: "#c573de",
      name: "#ff8080",
      function: "#59b4ff",
      constant: "#00fff3",
      string: "#6eff59",
      number: "#00fff3",
      comment: "#008000",
      operator: "#b4b4b4",
      class: "#267f99",
      type: "#ff8f00",
      variable: "#ff8080",
    },
    ui: {
      sideBar: "#1b1e2b",
      activityBar: "#1b1e2b",
      titleBar: "#1b1e2b",
      tabs: "#292c3e",
      tabsActive: "#232636",
      tabsActiveBorder: "#5affb2",
      borders: "#00000059",
      buttons: "#2a4c82",
      buttonsHover: "#1d9bee",
      text: "#b1e2ff",
      sideBarText: "#b1e2ff",
      activityBarText: "#fff66d",
      titleBarText: "#b1e2ff",
      panels: "#1b1e2b",
    },
  },
  {
    name: "Simple Orange",
    type: "dark",
    editor: {
      background: "#1b1b1b",
      foreground: "#d4d4d4",
      lineHighlight: "#ffffff0A",
      selection: "#fb6e073e",
      cursor: "#aeafad",
      gutterBackground: "#181818",
      gutterForeground: "#858585",
    },
    syntax: {
      keyword: "#c678dd",
      name: "#e06c75",
      function: "#61afef",
      constant: "#d19a66",
      string: "#98c379",
      number: "#d19a66",
      comment: "#008000",
      operator: "#d4d4d4",
      class: "#267f99",
      type: "#e5c07b",
      variable: "#e06c75",
    },
    ui: {
      sideBar: "#1b1b1b",
      activityBar: "#171717",
      titleBar: "#171717",
      tabs: "#242424",
      tabsActive: "#171717",
      tabsActiveBorder: "#00000000",
      borders: "#fb6e07",
      buttons: "#fb6e07",
      buttonsHover: "#be5406",
      text: "#cccccc",
      sideBarText: "#cccccc",
      activityBarText: "#fb6e07",
      titleBarText: "#cccccc",
      panels: "#171717",
    },
  },
  {
    name: "ModernUI",
    type: "dark",
    editor: {
      background: "#171616",
      foreground: "#d9d9d9",
      lineHighlight: "#ffffff0A",
      selection: "#2e546b",
      cursor: "#b2b2b2",
      gutterBackground: "#171616",
      gutterForeground: "#ffffff",
    },
    syntax: {
      keyword: "#ff6671",
      name: "#ff6671",
      function: "#ffea3f",
      constant: "#ffc049",
      string: "#ff8502",
      number: "#ffc049",
      comment: "#008000",
      operator: "#d9d9d9",
      class: "#267f99",
      type: "#ffea3f",
      variable: "#ff6671",
    },
    ui: {
      sideBar: "#171616",
      activityBar: "#171616",
      titleBar: "#1d1d1d",
      tabs: "#1b1b1b",
      tabsActive: "#171616",
      tabsActiveBorder: "#00000000",
      borders: "#80808059",
      buttons: "#123468",
      buttonsHover: "#2556a3",
      text: "#ffffff",
      sideBarText: "#ffffff",
      activityBarText: "#ffffff",
      titleBarText: "#ffffff",
      panels: "#171616",
    },
  },
  {
    name: "Midnight",
    type: "dark",
    editor: {
      background: "#030c1b",
      foreground: "#fbe179",
      lineHighlight: "#ffffff0A",
      selection: "#264f78",
      cursor: "#aeafad",
      gutterBackground: "#030c1b",
      gutterForeground: "#d4c1ff62",
    },
    syntax: {
      keyword: "#fc60a1",
      name: "#9cdcfe",
      function: "#4d91fb",
      constant: "#fb5474",
      string: "#d0cbeb",
      number: "#fb5474",
      comment: "#008000",
      operator: "#fbe179",
      class: "#267f99",
      type: "#66ffb4",
      variable: "#9cdcfe",
    },
    ui: {
      sideBar: "#000815",
      activityBar: "#000815",
      titleBar: "#000815",
      tabs: "#000815",
      tabsActive: "#00112a",
      tabsActiveBorder: "#00000000",
      borders: "#80808059",
      buttons: "#0e639c",
      buttonsHover: "#1177bb",
      text: "#cccccc",
      sideBarText: "#cccccc",
      activityBarText: "#ffffff",
      titleBarText: "#cccccc",
      panels: "#000815",
    },
  },
];

const mergeDefaultThemes = (themes: Theme[]): Theme[] => {
  const mergedThemes = [...defaultThemes];

  themes.forEach((theme: Theme) => {
    const idx = defaultThemes.findIndex((dTheme) => theme.name == dTheme.name);

    if (idx == -1) {
      mergedThemes.push(theme);
    }
  });

  return mergedThemes;
};

export interface ThemeState {
  currentTheme: number;
  themes: Theme[];
}

export const createThemeStore = () => {
  if (typeof window === "undefined") {
    globalStore.createStore(
      "themesStore",
      {
        themes: mergeDefaultThemes([]),
        currentTheme: 0,
      },
      []
    );
    return;
  }

  const initialThemeState: ThemeState = {
    themes: mergeDefaultThemes(
      JSON.parse(localStorage.getItem("themes") || "[]")
    ),
    currentTheme: JSON.parse(localStorage.getItem("savedTheme") || "0"),
  };

  globalStore.createStore("themesStore", initialThemeState, []);
};
