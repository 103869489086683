import Router from "next/router";
import request, { RequestMethod } from "./requestClient";

export const setToken = (
  accessToken: string,
  idToken: string,
  refreshToken: string
) => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("idToken", idToken);
  localStorage.setItem("refreshToken", refreshToken);

  setTimeout(function () {
    refreshTokens(refreshToken);
  }, 500 * 1000);
};

export const refreshTokens = (
  token: string | null,
  updateUserCallback: CallableFunction | null = null
) => {
  if (!token) {
    signOut(window.location.pathname + window.location.search);
    return;
  }

  fetch(process.env.NEXT_PUBLIC_OIDC_URI + "/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: token,
      client_id: process.env.NEXT_PUBLIC_OIDC_CLIENT_ID,
    }),
  })
    .then(async (res) => {
      if (!res.ok) {
        console.debug("Refresh failed: " + (await res.text()));
        return softSignOut(window.location.pathname + window.location.search);
      }

      const resJson = await res.json();

      setToken(resJson.access_token, resJson.id_token, resJson.refresh_token);
      if (updateUserCallback) {
        const endpoint = request.endpoint(`auth/me`);
        const res = await request.call(
          endpoint,
          request.createDefaultParams(RequestMethod.GET)
        );
        const resJson = await res.json();
        updateUserCallback(resJson);
      }
    })
    .catch((err) => {
      console.debug(err);
      softSignOut();
    });
};

const clearAuthenticationCredentials = (): string | null => {
  const token = localStorage.getItem("idToken");
  localStorage.removeItem("organizationId");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("idToken");
  localStorage.removeItem("refreshToken");
  return token;
};

const directToLoginPage = (redirectTo: string | null = null) => {
  if (!redirectTo) {
    Router.replace("/login");
  } else {
    Router.replace("/login?redirect-to=" + encodeURIComponent(redirectTo));
  }
};

export const signOut = (redirectTo: string | null = null) => {
  const idToken = clearAuthenticationCredentials();
  if (idToken) {
    Router.replace(
      process.env.NEXT_PUBLIC_OIDC_URI +
        "/logout?id_token_hint=" +
        idToken +
        "&post_logout_redirect_uri=" +
        encodeURIComponent(
          window.location.origin +
            "/login" +
            (redirectTo ? "?redirect-to=" + encodeURIComponent(redirectTo) : "")
        )
    );
  } else {
    directToLoginPage(redirectTo);
  }
};

export const softSignOut = (redirectTo: string | null = null) => {
  const idToken = clearAuthenticationCredentials();
  if (idToken) {
    Router.replace(
      process.env.NEXT_PUBLIC_OIDC_URI +
        "/logout?id_token_hint=" +
        idToken +
        "&post_logout_redirect_uri=" +
        encodeURIComponent(window.location.origin + "/login")
    );
  } else {
    directToLoginPage(redirectTo);
  }
};
