import { globalStore } from "@/lib/store";
import { useStore } from "@/lib/store";
import request from "@/lib/requestClient";
import Router from "next/router";

import { Project, Feature, ProjectFile, SelectedFeature } from "@/lib/types";

var initialProjects: Project[] = [];

const makeProjectMapping = (projects: Project[]): Record<string, Project> => {
  const projectsMapping: Record<string, Project> = {};

  projects.forEach((project) => {
    projectsMapping[project.id] = project;
  });

  return projectsMapping;
};

const getFeatures = (projects: Project[]): Record<number, Feature[]> => {
  const featuresByProjectId: Record<number, Feature[]> = {};

  if (projects === null || projects === undefined) {
    return featuresByProjectId;
  }

  for (let project of projects) {
    featuresByProjectId[project.id] = project.features;
  }

  return featuresByProjectId;
};

const getProjectKeys = (projects: Project[]): number[] => {
  return projects.map((project) => project.id);
};

interface ProjectState {
  projects: any[];
  projectsLoaded: boolean;
  files: Record<string, ProjectFile>;
  project: number | null;
  feature: SelectedFeature;
  entryPointNode: string | null;
}

const initialProjectState: ProjectState = {
  projects: initialProjects,
  projectsLoaded: false,
  files: {},
  project: null,
  feature: {
    invokeURL: null,
    id: null,
  },
  entryPointNode: null,
};

export const getProjectFromId = (id: number): Project | undefined => {
  const projectState: ProjectState = globalStore.readStore("projectStore");
  const projects: Project[] = projectState["projects"];

  return projects.find((project: Project) => project.id === id);
};

export const getAndSetProjects = () => {
  const projectState: ProjectState = globalStore.readStore("projectStore");
  const project = projectState["project"];

  const userState = globalStore.readStore("userStore");
  const organization = userState["organization"];

  const endpoint = request.endpoint(
    `organizations/${organization.id}/projects/list`
  );

  request.call(endpoint, request.createDefaultParams()).then(async (res) => {
    const resJson = await res.json();

    globalStore.updateStore("projectStore", {
      projects: resJson.projects,
      projectsLoaded: true,
    });

    if (resJson.projects.length > 0 && project == null) {
      Router.replace("/projects");
    }
  });
};

export const createProjectStore = () => {
  if (globalStore.stores["projectStore"] == null) {
    globalStore.createStore("projectStore", initialProjectState, [
      { name: "mapping", source: "projects", operation: makeProjectMapping },
      { name: "keys", source: "projects", operation: getProjectKeys },
      { name: "features", source: "projects", operation: getFeatures },
    ]);
  }
};
