import { globalStore } from "@/lib/store";

const initialState = {
    user: undefined,
    organization: undefined, 
}

export const createUserStore = () => {
    if (globalStore.stores["userStore"] == null) {
        globalStore.createStore("userStore", initialState, []);
    }
}