import React from 'react';

// Type for BaseIcon props
interface BaseIconProps {
    color: string;
    pushRight?: boolean;
    children: React.ReactNode;
}

// BaseIcon Functional Component
const BaseIcon: React.FC<BaseIconProps> = ({ color, pushRight = true, children }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        style={{ marginRight: pushRight ? '20px' : '0', minWidth: 24 }}
    >
        {children}
    </svg>
);

// Icons as functional components (no additional props needed)
const SuccessIcon: React.FC = () => (
    <BaseIcon color="#1ABC9C">
        <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14' />
        <polyline points='22 4 12 14.01 9 11.01' />
    </BaseIcon>
);

const InfoIcon: React.FC = () => (
    <BaseIcon color="#FDFEFE">
        <circle cx='12' cy='12' r='10' />
        <line x1='12' y1='16' x2='12' y2='12' />
        <line x1='12' y1='8' x2='12' y2='8' />
    </BaseIcon>
);

const ErrorIcon: React.FC = () => (
    <BaseIcon color="#C0392B">
        <circle cx='12' cy='12' r='10' />
        <line x1='12' y1='8' x2='12' y2='12' />
        <line x1='12' y1='16' x2='12' y2='16' />
    </BaseIcon>
);

const CloseIcon: React.FC = () => (
    <BaseIcon color="#FFFFFF" pushRight={false}>
        <line x1='18' y1='6' x2='6' y2='18' />
        <line x1='6' y1='6' x2='18' y2='18' />
    </BaseIcon>
);

// Type for AlertTemplate props
interface AlertTemplateProps {
    options: { type: 'info' | 'success' | 'error' };
    message: string;
    close: () => void;
}


const AlertTemplate: React.FC<AlertTemplateProps> = ({ options, message, close }) => {
    return (
        <div className="bg-[#151515] text-white p-2.5 uppercase rounded-sm flex justify-between items-center shadow-md font-sans w-[300px] box-border mt-[5em] ml-[90px]">
            {options.type === 'info' && <InfoIcon />}
            {options.type === 'success' && <SuccessIcon />}
            {options.type === 'error' && <ErrorIcon />}
            <span className="flex-1">{message}</span>
            <button onClick={close} className="ml-5 border-none bg-transparent cursor-pointer text-white">
                <CloseIcon />
            </button>
        </div>
    )
}

export default AlertTemplate;
