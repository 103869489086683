import { refreshTokens } from "./authentication";
import { fetchEventSource, EventSourceMessage } from '@microsoft/fetch-event-source';
export class SSERetriableError extends Error { }
export class SSEFatalError extends Error { }

const deepMerge = require('deepmerge')

var unauthorizedRedirect = function (res: any) {
  if (res.status === 401) {
    const token = localStorage.getItem("refreshToken");
    refreshTokens(token);
  }
};

var alertsMiddleware = function (alertHandler: any, message: any) {
  return async (res: any) => {
    if (res.status < 400) {
      const alert = message || res.body || res.text;
      alertHandler.success(alert);
    } else if (res.status > 401 || res.status === 400) {
      let alert = "Server had a hiccup try again in a bit.";

      if (res.body || res.text) {
        const text = await res.text()

        if (text.length < 50) {
          alert = text
        }
      }

      alertHandler.error(alert);
    }
  };
};

export enum RequestMethod {
  GET = "GET",
  PUT = "PUT",
  POST = "POST",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
  DELETE = "DELETE"
}

interface RequestParams extends RequestInit {
  headers: Record<string, string>,
  authed: boolean,
  resultMiddleware: { (res: any): void }[]
  onopen?: CallableFunction
  onerror?: CallableFunction
  onclose?: CallableFunction
}

export const createDefaultParams = (method: RequestMethod = RequestMethod.GET): RequestParams => {
  return {
    headers: {
      "Content-Type": "application/json"
    },
    method: method,
    resultMiddleware: [],
    authed: true,
    mode: "cors"
  }
}

const request = {
  endpoint: (path: string): string => {
    const apiEndpoint = process.env.NEXT_PUBLIC_API_URI

    return `${apiEndpoint}/${path}`
  },
  createDefaultParams: (method: RequestMethod = RequestMethod.GET): RequestParams => {
    return createDefaultParams(method)
  },
  applyDefaults: (requestParams: RequestParams, alertHandler = null, message = null) => {
    var defaultParams = request.createDefaultParams(RequestMethod.GET)

    const token = localStorage.getItem("accessToken");

    if (token && requestParams.authed && requestParams.method !== RequestMethod.OPTIONS) {
      defaultParams.headers['Authorization'] = "Bearer " + token
    }

    defaultParams.resultMiddleware.push(unauthorizedRedirect);

    if (alertHandler) {
      defaultParams.resultMiddleware.push(alertsMiddleware(alertHandler, message));
    }

    return deepMerge(defaultParams, requestParams);
  },
  callSSE: (url: string, params: RequestParams, messageHandler: (msg: EventSourceMessage) => void, alertHandler = null, message = null): AbortController => {
    // @ts-ignore
    const requestParams = request.applyDefaults(params, alertHandler, message)

    try {
      const abortController = new AbortController()
      fetchEventSource(url, {
        onmessage(msg) {
          if (msg.event === 'FatalError') {
            throw new SSEFatalError(msg.data)
          }

          messageHandler(msg)
        },
        signal: abortController.signal,
        ...requestParams
      })
      return abortController
    } catch (err) {
      console.error(err)
      throw err
    }
  },
  call: async (url: string, params: RequestParams, alertHandler = null, message: string | null = null) => {
    // @ts-ignore
    const requestParams = request.applyDefaults(params, alertHandler, message)

    const requestObject = new Request(url, requestParams)


    try {
      const res = await fetch(requestObject)
      requestParams.resultMiddleware.forEach((middleware: { (res: any): void }) => {
        middleware(res.clone())
      })

      return res
    } catch (err) {
      console.error(err)
      throw err
    }

  }
}

export default request;

